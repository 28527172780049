import IProduct from '../../types/product';
import { ProductVariant } from '../../types/productVariant';

export type ShopwareFieldsIncludes = {
    includes?: Record<string, Array<string>>;
    associations?: Record<string, unknown>;
};

export type ShopwareProductFieldsKeys = keyof IProduct | 'variantsOnlyOptions';
export type ShopwareProductRequestBodyFields = {
    [key in ShopwareProductFieldsKeys]: ShopwareFieldsIncludes;
};

export type ShopwareProductVariantFieldsKeys = keyof ProductVariant;
export type ShopwareProductVariantRequestBodyFields = {
    [key in ShopwareProductVariantFieldsKeys]: ShopwareFieldsIncludes;
};

export type ShopwareProductRequestBodySelectorFilters = {
    filter?: Array<{ type: string; field: string; value: string | string[] | null }>;
};

export const ALL_FIELDS_PRODUCT: ShopwareProductRequestBodyFields = {
    id: {
        includes: {
            product: ['id'],
        },
    },
    objectID: {
        includes: {
            product: ['id'],
        },
    },
    sku: {
        includes: {
            product: ['productNumber'],
        },
    },
    ean: {
        includes: {
            product: ['ean'],
        },
    },
    name: {
        includes: {
            product: ['name', 'translated.name'],
        },
    },
    brand: {
        includes: {
            product: ['manufacturer'],
            product_manufacturer: ['name', 'translated.name'],
        },
        associations: {
            manufacturer: {},
        },
    },
    metaTitle: {
        includes: {
            product: ['metaTitle', 'translated.metaTitle'],
        },
    },
    metaDescription: {
        includes: {
            product: ['metaDescription', 'translated.metaDescription'],
        },
    },
    metaKeywords: {
        includes: {
            product: ['keywords', 'translated.keywords'],
        },
    },
    price: {
        includes: {
            product: ['calculatedPrice', 'calculatedPrices'],
            calculated_price: ['totalPrice', 'calculatedTaxes'],
        },
    },
    listPrice: {
        includes: {
            product: ['calculatedPrice', 'calculatedPrices'],
            calculated_price: ['listPrice', 'calculatedTaxes'],
        },
    },
    taxPrice: {
        includes: {
            product: ['calculatedPrice', 'calculatedPrices'],
            calculated_price: ['listPrice', 'calculatedTaxes'],
        },
    },
    regulationPrice: {
        includes: {
            product: ['calculatedPrice', 'calculatedPrices'],
            calculated_price: ['regulationPrice'],
        },
    },
    categories: {
        includes: {
            product: ['categories', 'categoriesRo', 'categoryTree', 'tags'], // `categoriesRo` returns the full tree of categories from Shopware
            tag: ['id', 'name'],
            category: ['id', 'name', 'translated.name', 'tags'],
        },
        associations: {
            tags: {},
            categories: {
                associations: {
                    tags: {},
                },
            },
            categoriesRo: {},
        },
    },
    categoriesRo: {
        includes: {
            product: ['categories', 'categoriesRo', 'categoryTree'], // `categoriesRo` returns the full tree of categories from Shopware
            category: ['id', 'name', 'translated.name'],
        },
        associations: {
            categories: {},
            categoriesRo: {},
        },
    },
    categoryTree: {
        includes: {
            product: ['categories', 'categoriesRo', 'categoryTree'], // `categoriesRo` returns the full tree of categories from Shopware
            category: ['id', 'name', 'translated.name'],
        },
        associations: {
            categories: {},
            categoriesRo: {},
        },
    },
    type: {
        includes: {
            product: ['categories', 'categoriesRo'], // `categoriesRo` returns the full tree of categories from Shopware
            category: ['id', 'name', 'translated.name'],
        },
        associations: {
            categories: {},
            categoriesRo: {},
        },
    },
    legalNotes: {
        includes: {
            product: ['categories', 'categoriesRo'], // `categoriesRo` returns the full tree of categories from Shopware
            category: ['id', 'customFields', 'translated.customFields'], // customFields|translated.customFields used for legalNotes
        },
        associations: {
            categories: {},
            categoriesRo: {},
        },
    },
    img: {
        includes: {
            product: ['cover'],
            product_media: ['media'],
            media: ['url'],
        },
    },
    imgThumbnails: {
        includes: {
            product: ['cover'],
            product_media: ['media'],
            media: ['thumbnails'],
            media_thumbnail: ['id', 'url', 'width', 'height'],
        },
    },
    imgAlt: {
        includes: {
            product: ['cover'],
            product_media: ['media'],
            media: ['alt'],
        },
    },
    customFields: {
        includes: {
            product: ['customFields', 'translated.customFields'],
        },
    },
    description: {
        includes: {
            product: ['description', 'translated.description'],
        },
    },
    keyFeatures: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_key_feature_<1|2|3>_media
        },
    },
    hero: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_hero_image
        },
    },
    heroAlt: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_hero_image
        },
    },
    heroThumbnails: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_hero_image
        },
    },
    geometry: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_geometry_image
        },
    },
    geometryAlt: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_geometry_image
        },
    },
    modelLogo: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_model_logo
        },
    },
    bikeLogo: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_bike_logo
        },
    },
    halfords: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_halfords_url
        },
    },
    subtitle: {
        includes: {
            product: ['customFields', 'translated.customFields'], // customFields.accell_products_subtitle
        },
    },
    images: {
        includes: {
            product: ['media'],
            product_media: ['id', 'media', 'position'],
            media: ['id', 'alt', 'url', 'thumbnails', 'metaData', 'translated.alt'],
            media_thumbnail: ['id', 'url', 'width', 'height'],
        },
        associations: {
            media: {},
        },
    },
    inactive: {
        includes: {
            product: ['active'],
        },
    },
    stockStatus: {
        includes: {
            product: ['active', 'availableStock'],
        },
    },
    createdAt: {
        includes: {
            product: ['createdAt'],
        },
    },
    updatedAt: {
        includes: {
            product: ['updatedAt'],
        },
    },
    product: {
        includes: {
            product: ['parentId'],
        },
    },
    attributes: {
        includes: {
            product: ['properties'],
            property_group_option: [
                'id',
                'name',
                'translated.name',
                'group',
                'position',
                'translated.position',
                'displayType',
                'colorHexCode',
            ],
            property_group: [
                'id',
                'name',
                'translated.name',
                'position',
                'translated.position',
                'displayType',
                'filterable',
                'options',
            ],
        },
        associations: {
            properties: {
                associations: {
                    group: {},
                },
            },
        },
    },
    attributesHidden: {
        includes: {
            product: ['properties'],
            property_group_option: [
                'id',
                'name',
                'translated.name',
                'group',
                'position',
                'translated.position',
                'displayType',
                'colorHexCode',
            ],
            property_group: [
                'id',
                'name',
                'translated.name',
                'position',
                'translated.position',
                'displayType',
                'filterable',
                'options',
            ],
        },
        associations: {
            properties: {
                associations: {
                    group: {},
                },
            },
        },
    },
    variantIds: {
        includes: {
            product: ['children'],
        },
        associations: {
            children: {},
        },
    },
    variantsOnlyOptions: {
        includes: {
            product: ['children'],
        },
        // This is a limited version of the variants with associations, since more associations are heavy on the backend
        associations: {
            children: {
                associations: {
                    options: {
                        associations: {
                            group: {},
                        },
                    },
                },
            },
        },
    },
    variants: {
        includes: {
            product: ['children'],
        },
        associations: {
            children: {
                associations: {
                    cover: {},
                    media: {},
                    options: {
                        associations: {
                            group: {},
                        },
                    },
                },
            },
        },
    },
};

export const ALL_FIELDS_PRODUCT_VARIANT: ShopwareProductVariantRequestBodyFields = {
    ...ALL_FIELDS_PRODUCT,

    secondaryColor: {
        includes: {
            product: ['properties'],
            property_group_option: ['id', 'name', 'translated.name', 'group'],
            property_group: ['id', 'name'],
        },
        associations: {
            properties: {
                associations: {
                    group: {},
                },
            },
        },
    },
    options: {
        includes: {
            product: ['options'],
            property_group_option: [
                'id',
                'name',
                'translated.name',
                'group',
                'position',
                'translated.position',
                'displayType',
                'colorHexCode',
            ],
            property_group: [
                'id',
                'name',
                'translated.name',
                'position',
                'translated.position',
                'displayType',
                'filterable',
                'options',
            ],
        },
        associations: {
            options: {
                associations: {
                    group: {},
                },
            },
        },
    },
    shape: {
        includes: {
            product: ['options'],
            property_group_option: ['id', 'name', 'translated.name', 'group'],
            property_group: ['id', 'name'],
        },
        associations: {
            options: {
                associations: {
                    group: {},
                },
            },
        },
    },
    color: {
        includes: {
            product: ['options'],
            property_group_option: ['id', 'name', 'translated.name', 'group'],
            property_group: ['id', 'name'],
        },
        associations: {
            options: {
                associations: {
                    group: {},
                },
            },
        },
    },
};

export const ALL_FILTERS = {
    noVariants: {
        filter: [{ type: 'equals', field: 'parentId', value: null }],
    },
};

export const ALL_SELECTOR_FILTERS = {
    noVariants: () => ({
        filter: [{ type: 'equals', field: 'parentId', value: null }],
    }),
    getByProductNumber: (value: string[]) => ({
        filter: [{ type: 'equalsAny', field: 'productNumber', value }],
    }),
    getByProductId: (value: string[]) => ({
        filter: [{ type: 'equalsAny', field: 'id', value }],
    }),
};
